"use client";
import Link from "next/link";
import { useState } from "react";
import { Accordion } from "react-bootstrap";
const Hamburger = (props: any) => {
  const { modalClass, onModalClose, templateData } = props;
  const [show, setShow] = useState(false);
  const [filterMenuData, setFilterMenuData]: any = useState("");

  const handleDropDown = () => {
    setShow(!show);
  };

  const changeMenuData = (data: any) => {
    if (data) {
      setFilterMenuData(data);
    }
  };

  const getDropdownMenuMobile = (menu: any) => {
    return (
      <>
        <Accordion defaultActiveKey="0" className="mobile_multi_menu_acc">
          <Accordion.Header>
            {menu?.title} <img src="/svg/dropdown.svg" />
          </Accordion.Header>
          <Accordion.Body>
            {menu &&
              menu?.sub_menu &&
              menu?.sub_menu.map((menuItem: any, index: number) => {
                return (
                  <Link
                    href={menuItem?.link}
                    target={
                      menuItem?.open_in_new_tab == true ? "_blank" : "_self"
                    }
                    key={index}
                  >
                    {" "}
                    {menuItem?.title}
                  </Link>
                );
              })}
          </Accordion.Body>
        </Accordion>
      </>
    );
  };

  return (
    <div className={`hamburger-container ${modalClass}`}>
      <div className="hamburger-innercontainer">
        <div className="menulist">
          <ul>
            {templateData?.header_left_menu?.map((item: any, index: any) => {
              return (
                <>
                  {item?.title == "Our Collection" ? (
                    <li key={index} className={`${show ? "menuColor" : ""}`}>
                      <a
                        onClick={() => {
                          handleDropDown(), setFilterMenuData("");
                        }}
                        className={`${show ? "menuColor" : ""}`}
                      >
                        {item.title} <img src="/svg/dropdown.svg" />
                      </a>
                      {show && (
                        <>
                          {!filterMenuData ? (
                            <div className="dropdown-item our_collection_menu">
                              {item?.sub_menu.map((list: any, index: any) => {
                                return (
                                  <li key={index}>
                                    <div className="our_collection_links_tag">
                                      <Link
                                        href={list?.link ? list?.link : "/"}
                                        target={
                                          list?.open_in_new_tab == true
                                            ? "_blank"
                                            : "_self"
                                        }
                                      >
                                        {list?.title}
                                      </Link>
                                      <div
                                        className="arrow_image"
                                        onClick={() => changeMenuData(list)}
                                      >
                                        <img
                                          src="/product/right-arrow.png"
                                          alt="arrow"
                                        />
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </div>
                          ) : (
                            <div className="dropdown-item our_collection_menu">
                              <li>
                                <div className="our_collection_links_tag sub_menu">
                                  <div
                                    className="dash_arrow"
                                    onClick={() => setFilterMenuData("")}
                                  >
                                    <img
                                      src="/product/back-arrow.png"
                                      alt="arrow"
                                    />
                                  </div>
                                  <Link
                                    href={
                                      filterMenuData?.link
                                        ? filterMenuData?.link
                                        : "/"
                                    }
                                    target={
                                      filterMenuData?.open_in_new_tab == true
                                        ? "_blank"
                                        : "_self"
                                    }
                                  >
                                    {filterMenuData?.title}
                                  </Link>
                                </div>
                              </li>
                              {filterMenuData?.sub_menu.map(
                                (list: any, index: any) => {
                                  return (
                                    <li key={index}>
                                      <div className="our_collection_links_tag sub_menu">
                                        <div className="dash_arrow">
                                          <img
                                            src="/product/dash-arrow.png"
                                            alt="arrow"
                                          />
                                        </div>
                                        <Link
                                          href={list?.link ? list?.link : "/"}
                                          target={
                                            list?.open_in_new_tab == true
                                              ? "_blank"
                                              : "_self"
                                          }
                                        >
                                          {list?.title}
                                        </Link>
                                      </div>
                                    </li>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </li>
                  ) : (
                    <li key={index} className={`${show ? "menuColor" : ""}`}>
                      <Link
                        href={item?.link ? item?.link : "/"}
                        target={
                          item?.open_in_new_tab == true ? "_blank" : "_self"
                        }
                      >
                        {item?.title}
                      </Link>
                    </li>
                  )}
                </>
              );
            })}
            {templateData?.header_right_menu?.map((item: any, index: any) => {
              return item?.title === "Contact" ? (
                <li key={index}>{getDropdownMenuMobile(item)}</li>
              ) : (
                <li
                  key={index}
                  onClick={onModalClose}
                  className={item?.title === "Charity" ? "charity_menu " : ""}
                >
                  <Link
                    href={item?.link ? item?.link : "/"}
                    target={item?.open_in_new_tab == true ? "_blank" : "_self"}
                  >
                    {item?.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="social-media-wrapper">
          {templateData?.header_social_media_links?.map(
            (item: any, index: any) => {
              return (
                <span key={index}>
                  <Link
                    href={item?.link ? item?.link : "/"}
                    target={item?.open_in_new_tab == true ? "_blank" : "_self"}
                  >
                    <img
                      src={
                        item?.image?.data?.attributes.url
                          ? item?.image?.data?.attributes.url
                          : ""
                      }
                    />
                  </Link>
                </span>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default Hamburger;
