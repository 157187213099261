"use client";
import React, { useState, useEffect } from "react";
import Hamburger from "../HamburgerMenu/Hamburger";
import Link from "next/link";
import { useLocalsListStore } from "@/zustand/localesList";
import { getCookie, setCookie } from "../../../../helper/helper";
import { usePathname, useRouter } from "next/navigation";
import SelectLocation from "@/components/selectElement/selectLocation";
import { Dropdown, NavDropdown } from "react-bootstrap";
import {
  getCheckoutLineItems,
  setRemoveLineItemInCart,
  setUpdateQuantityInCart,
} from "@/util/utils";
import BuyNowCart from "../BuyNowCart";

const Header = (props: any) => {
  const { countryData, templateData, scrolled } = props;
  const [isHamburgerActive, setHamburgerActive] = useState(false);
  const [show, setShow] = useState(false);
  const [showWhiskyMenu, setShowWhiskyMenu] = useState(false);
  const [selectedCountry, setSelectedCountry]: any = useState({
    label: "USA",
    value: "en-US",
  });

  let selectedCountryObj = getCookie("selectedCountry");
  const localesList = useLocalsListStore((state: any) => state.localesList);
  const locale = localesList.locale || getCookie("locale");
  const setlocalesList = useLocalsListStore(
    (state: any) => state.setlocalesList
  );

  const hamburgermodal = () => {
    setHamburgerActive(!isHamburgerActive);
    // document.body.classList.toggle("overflow-hidden");
  };

  const router = useRouter();
  const onCountrySelect = (data: any) => {
    setCookie("locale", data.value);
    const params = {
      locale: data.value,
    };
    setlocalesList(params);
    router.push("/");
    setShow(!show);
  };

  const handleDropDown = () => {
    setShow(!show);
  };

  const onDataChange = (data: any) => {
    if (onCountrySelect) {
      onCountrySelect(data);
    }
    if (data) {
      const newVal = { label: data.label, value: data.value };
      setSelectedCountry(newVal);
      setCookie("selectedCountry", JSON.stringify(newVal));
    }
  };

  useEffect(() => {
    if (selectedCountryObj) {
      setSelectedCountry(JSON.parse(selectedCountryObj));
      const params = {
        locale: JSON.parse(selectedCountryObj).value,
      };
      setlocalesList(params);
    }
  }, [selectedCountryObj]);

  // useEffect(() => {
  //   getCheckoutLineItems().then((res) => {
  //     setCheckout({
  //       ...res,
  //     });
  //   });
  // }, []);

  // const updateQuantityInCart = (lineItemId: any, quantity: any) => {
  //   const lineItemsToUpdate = [
  //     { id: lineItemId, quantity: parseInt(quantity, 10) },
  //   ];
  //   setUpdateQuantityInCart(lineItemsToUpdate).then((res) => {
  //     setCheckout({
  //       ...res,
  //     });
  //   });
  // };

  // const removeLineItemInCart = (lineItemId: any) => {
  //   setRemoveLineItemInCart(lineItemId).then((res) => {
  //     setCheckout({
  //       ...res,
  //     });
  //   });
  // };

  const handleMenuBox = () => {
    setShowWhiskyMenu(!showWhiskyMenu);
  };

  const path = usePathname();

  const whiteHeaderPath: any = ["/shop-now"];

  const getDropdownMenu = (menu: any) => {
    return (
      <div onClick={() => setShowWhiskyMenu(false)}>
        <Dropdown className="header_menu_dropdown">
          <Dropdown.Toggle variant="success">
            {menu?.title}
            <img src="/svg/dropdown.svg" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {menu &&
              menu?.sub_menu &&
              menu?.sub_menu.map((menuItem: any, index: number) => {
                return (
                  <Dropdown.Item
                    href={menuItem?.link}
                    target={
                      menuItem?.open_in_new_tab == true ? "_blank" : "_self"
                    }
                    key={index}
                  >
                    {menuItem?.title}
                  </Dropdown.Item>
                );
              })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  return (
    <div
      className={`header-outerwrapper ${
        whiteHeaderPath.includes(path) ? "white_header" : ""
      }`}
    >
      <div className="reactselect-wrapperHamberger mobile_country_select">
        <SelectLocation
          selectedCountry={selectedCountry}
          countryData={countryData}
          onDataChange={onDataChange}
        />
      </div>
      <div className="header-container">
        {isHamburgerActive ? (
          <img
            src="/svg/close.svg"
            className="menu_mobile"
            onClick={hamburgermodal}
          />
        ) : (
          <img
            src="/svg/menu_mobile.svg"
            className="menu_mobile"
            onClick={hamburgermodal}
          />
        )}
        <div className="left_section">
          <SelectLocation
            selectedCountry={selectedCountry}
            countryData={countryData}
            onDataChange={onDataChange}
          />
          <ul>
            {templateData?.header_left_menu?.map((item: any, index: any) => {
              return (
                <>
                  {item?.title == "Our Collection" ? (
                    <li
                      className={`${showWhiskyMenu ? "menuColor" : ""}`}
                      key={index}
                    >
                      <a
                        onClick={handleMenuBox}
                        className={`${showWhiskyMenu ? "menuColor" : ""}`}
                      >
                        {item.title}
                        <img src="/svg/dropdown.svg" />
                      </a>
                    </li>
                  ) : (
                    <li key={index} className={`${show ? "menuColor" : ""}`}>
                      <Link
                        href={item?.link ? item?.link : "/"}
                        target={
                          item?.open_in_new_tab == true ? "_blank" : "_self"
                        }
                      >
                        {item?.title}
                      </Link>
                    </li>
                  )}
                </>
              );
            })}
          </ul>
          <div
            className={`blended_whisky_menu_box ${scrolled ? "scrolled" : ""} ${
              showWhiskyMenu ? "open" : "close"
            }`}
          >
            {templateData?.header_left_menu?.map((item: any) => {
              if (item?.title === "Our Collection") {
                return (
                  <>
                    {item?.sub_menu?.map((menuItem: any, index: number) => {
                      return (
                        <>
                          <div className="inner_menu" key={index}>
                            <h2> {menuItem?.title}</h2>

                            <div className="list_of_menu">
                              {menuItem?.sub_menu?.map(
                                (subMenu: any, index: number) => {
                                  return (
                                    <Link
                                      href={subMenu?.link ? subMenu?.link : "/"}
                                      key={index}
                                      target={
                                        subMenu?.open_in_new_tab ? "_blank" : ""
                                      }
                                      onClick={() => setShowWhiskyMenu(false)}
                                    >
                                      {" "}
                                      {subMenu?.title}
                                    </Link>
                                  );
                                }
                              )}
                            </div>
                          </div>
                          {item?.sub_menu.length !== index + 1 ? (
                            <div className="vertical_line_div"></div>
                          ) : null}
                        </>
                      );
                    })}
                  </>
                );
              }
            })}
          </div>
        </div>
        {templateData?.header_logo_white?.data?.attributes.url && (
          <div className="site-logo_wrapper">
            <Link href="/">
              <img
                src={
                  templateData?.header_logo_white?.data?.attributes.url
                    ? templateData?.header_logo_white?.data?.attributes.url
                    : ""
                }
                className="site-logo"
              />
            </Link>
          </div>
        )}

        {(locale == "en-GB" || locale == "en-US") && (
          <span
            style={{ cursor: "pointer" }}
            onClick={props?.toggleCartSidebar}
            className="cart_icon_for_mobile"
          >
            <img
              src="/rarestcollection/cart-icon.png"
              alt="Cart Icon"
              className="cart_icon"
            />
            <div className="cart-count" id="cartCount">
              {props?.checkout && props?.checkout?.lineItems
                ? props?.checkout?.lineItems.length
                : 0}
            </div>
          </span>
        )}

        <div className="right_section">
          <ul>
            {templateData?.header_right_menu?.map((item: any, index: any) => {
              return (
                <li
                  key={index}
                  className={item?.title === "Charity" ? "charity_menu" : ""}
                >
                  {item?.title === "Contact" ? (
                    getDropdownMenu(item)
                  ) : (
                    <Link
                      href={item?.link ? item?.link : "/"}
                      target={
                        item?.open_in_new_tab == true ? "_blank" : "_self"
                      }
                    >
                      {item?.title}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>

          <div className="social-media-wrapper">
            {(locale == "en-GB" || locale == "en-US") && (
              <span
                style={{ cursor: "pointer", position: "relative" }}
                onClick={props?.toggleCartSidebar}
              >
                <img
                  src="/rarestcollection/cart-icon.png"
                  alt="Cart Icon"
                  className="cart_icon"
                />
                <div className="cart-count" id="cartCount">
                  {props?.checkout && props?.checkout?.lineItems
                    ? props?.checkout?.lineItems.length
                    : 0}
                </div>
              </span>
            )}

            {templateData?.header_social_media_links?.map(
              (item: any, index: any) => {
                return (
                  <span key={index}>
                    <Link
                      href={item?.link ? item?.link : "/"}
                      target={
                        item?.open_in_new_tab == true ? "_blank" : "_self"
                      }
                    >
                      <img
                        src={
                          item?.image?.data?.attributes.url
                            ? item?.image?.data?.attributes.url
                            : ""
                        }
                      />
                    </Link>
                  </span>
                );
              }
            )}
          </div>
        </div>
      </div>
      <Hamburger
        onModalClose={hamburgermodal}
        modalClass={`${isHamburgerActive ? "open" : ""}`}
        templateData={templateData}
        selectedCountry={selectedCountry}
        countryData={countryData}
        onDataChange={onDataChange}
      />
    </div>
  );
};

export default Header;
